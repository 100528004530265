@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Gochi+Hand&family=Limelight&family=Rock+Salt&family=Titan+One&family=Ultra&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #101010;
  color: #fff;
}
