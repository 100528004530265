.limelight-regular {
  font-family: "Limelight", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.gochi-hand-regular {
  font-family: "Gochi Hand", cursive;
  font-weight: 400;
  font-style: normal;
}
.titan-one-regular {
  font-family: "Titan One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.ultra-regular {
  font-family: "Ultra", serif;
  font-weight: 400;
  font-style: normal;
}
.rock-salt-regular {
  font-family: "Rock Salt", cursive;
  font-weight: 400;
  font-style: normal;
}
.abril-fatface-regular {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
}
